/* This is a CSS style sheet for rules that should be applied over the
   entire application.  We are not using the name prefix for these rules.  */

/* class="container" - Sets some margins around the element so it is spaced
                       from the top, bottom, and sides of the window. */
@font-face {
  font-family: "Source Sans Pro";
  src: url("/src/styles/Source_Sans_Pro/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "Source Sans Pro Medium";
  src: url("/src/styles/Source_Sans_Pro/SourceSansPro-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("/src/styles/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("/src/styles/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Arvo";
  src: url("/src/styles/Arvo/Arvo-Regular.ttf");
}

@font-face {
  font-family: "Arvo Bold";
  src: url("/src/styles/Arvo/Arvo-Bold.ttf");
}

:root {
  --theme-green: forestgreen;
  --theme-green-dark: darkgreen;
  --theme-green-logo: #889927;
  --theme-orange: #F95409;
  --theme-peach: peachpuff;
  --theme-red: #CF0414;
  --theme-burgundy: #A00047;
  --theme-purple: #8B008B;

  --text-mega: 30px;
  --text-large-mega: 24px;
  --text-large: 22px;
  --text-medium-large: 20px;
  --text-medium: 18px;
  --text-default: 16px;
  --text-small: 14px;
}


html {
  height: 100%;
  font-family: "Source Sans Pro";
  scroll-behavior: smooth;
  color: black;
}

body {
  min-height: 100%;
  font-family: "Source Sans Pro";
  /*courtesy of Shana E. Hadi name insert on index page!*/
}

.main-display {
  padding-left: 40px;
  padding-right: 40px;
}

a, p, li {
  font-family: "Source Sans Pro";
  color: black;
}

/* img {
  width: 225px;
  height: auto;
  border: 0.5px solid #ddd;
  border-radius: 4px;
  box-shadow: 7px 7px lightsalmon;
  transition: transform .2s;
}
img:hover {
  cursor: pointer;
  box-shadow: 7px 7px tomato;
  transform: scale(1.05);
} */
a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

.page-title {
  font-size: var(--text-mega);
  font-family: "Source Sans Pro Medium";
  text-align: center;
}

.bold-text {
  font-family: "Source Sans Pro Medium";
}

/*HEADER STYLES*/
.header {
  display: flex;
  position: sticky;
  top: 0;
  padding: 12px 40px 4px 40px;
  background-color: white;
  border-bottom: 3px solid darkgreen;
  /* box-shadow: 0 2px 4px lightgray; */
  z-index: 100;
}

.header-name {
  flex: 1;
  font-size: var(--text-mega);
  margin-right: 32px;
  font-family: "Arvo";
}

.header-nav {
  flex: 2;
  font-size: var(--text-medium-large);
  text-align: right;
  align-self: flex-end;
  padding-bottom: 4px;
}

.header-name-link,
.header-nav-link {
  text-decoration: none;
  color: darkgreen;
  font-family: "Arvo";
  /*#006400 dark green*/
}

/*resume color, better for text: #387023*/
.header-name-link:hover,
.header-nav-link:hover {
  cursor: pointer;
  /* text-decoration: underline; */
  color: forestgreen;
}

.external-logo-img {
  height: 36px;
  width: auto;
  margin-right: 8px;
  /* box-shadow: 3px 3px darkgreen;
  transition: transform .2s;
  border-radius: 4px; */
}

.external-logo-img:hover {
  cursor: pointer;
  transform: scale(1.1);
  /* box-shadow: 3px 3px forestgreen; */
}

.header-nav-img {
  height: 40px;
  width: auto;
  margin-bottom: -4px;
  padding-right: 8px;
}

/* .header-nav-img:hover, .header-name-link:hover .heaver-nav-img {
  transform: scale(1.05);
} */

.footer {
  margin: 0 auto;
  padding: 24px 12px 24px 12px;
  background-color: white;
  text-align: center;
  /* border-top: 3px solid darkgreen; */
}

.footer-logo {
  width: 60px;
}

.footer-text, .footer-text-handcraft {
  font-size: var(--text-medium);
  margin: 0;
}

.footer-img {
  max-height: 30px;
  padding: 6px;
  flex: 1;
}

.footer-img:hover {
  cursor: pointer;
  transform: scale(1.1);
}


.footer-link-container {
  padding-top: 16px;
}

.footer-email-container {
  display: flex;
  justify-content: center;
}

.footer-email-column {
  align-self: center;
}

.footer-email {
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border: 3px solid darkgreen;
  border-radius: 40px;
  transition: transform .4s;
  padding: 8px;
}

.footer-email:hover {
  cursor: pointer;
  border-color: forestgreen;
  /* box-shadow: 3px 3px #F95409; */
  transform: scale(1.02);
}

/*ABOUT STYLES*/
.about-main {
  font-size: var(--text-medium);
}

.about-hello-container {
  padding: 40px 0px 0px 0px;
  display: flex;
  justify-content: center;
}

.about-hello {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about-title {
  font-size: var(--text-mega);
  font-weight: bold;
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.about-title-blurb {
  font-size: var(--text-large-mega);
}

.about-title-blurb-more {
  margin-top: -0.25rem;
  font-size: var(--text-medium-large);
  max-width: 600px;
}

.about-external-link-container {
  flex-direction: row;
}

.about-vector-container {
  width: 400px;
}

.about-vector {
  width: 100%;
  height: auto;
  margin-bottom: -5px;
}

.about-vector-light {
  fill-opacity: 1;
}

/* .about-vector-light:hover {
  fill-opacity: 0.3;
} */

.about-stars-all {
  animation: blinker 3s infinite alternate;
  opacity: 0;
}

/*animate stars on hover over the vector image, no comma if child element, ~ if direct sibling */
.about-vector:hover .about-stars-all {
  /* animation: blinker 2s infinite alternate; */
}

/* .about-stars-all:hover, .about-vector-light:hover ~ .about-stars-all {
  animation: blinker 2s linear infinite alternate;
} */

/* .about-stars-all {
  animation: blinker 2s infinite alternate;
} */

@keyframes blinker {
  50% {
    opacity: 1;
  }
}


.about-desk-separator {
  height: 8px;
  background-color: #A09494;
  border: 2px solid black;
  border-radius: 4px;
}

/* .about-img {
  width: 225px;
  height: auto;
  margin-right: 12px;
  margin-bottom: 12px;
  border: 1px solid peachpuff;
  border-radius: 4px;
  box-shadow: 7px 7px lightsalmon;
  transition: transform .2s;
} */

/* .about-img:hover {
  cursor: pointer;
  box-shadow: 7px 7px tomato;
  transform: scale(1.05);
} */

.about-link {
  color: black;
  text-decoration: none;
  font-family: "Source Sans Pro Medium";
  border-bottom: 4px solid #F95409;

  background-image: linear-gradient(peachpuff, peachpuff);
  background-size: 100% 0px;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  transition: background-size 1s, background-position .5s ease-in-out;
}

.about-link:hover {
  /* background-color: peachpuff;
  border-bottom: 4px solid tomato; */
  background-image: linear-gradient(peachpuff, peachpuff);
  text-decoration: none;
  background-size: 100% 100%;
  background-position: 0% 100%;
  transition: background-position .7s, background-size .5s ease-in-out;
}

/* 
.about-list {
  padding-top: 4px;
  list-style-type: square;
} */

.about-skills-img {
  width: 40px;
}

.about-skills-img-small {
  width: 32px;
}

.about-skills-container {
  border: solid;
  border-color: #A09494;
  border-width: 12px 2px 2px 2px;
  /*N, E, S, W*/

  border-radius: 2px 2px 25px 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /*fr stands for flex factor (1fr for each column)*/
  text-align: center;
}

.about-skills-container>.about-skills-column:nth-child(1),
.about-skills-column:nth-child(2) {
  border-right: 2px solid #A09494;
}

.about-skills-column {
  padding: 20px;
  /* border: 2px solid #F95409;
  border-color: #FFEDB8;
  border-radius: 6px; */
}

.about-skills-column-title {
  font-weight: bold;
  color: darkgreen;
  font-size: var(--text-large);
  margin: 0;
}

.about-skills-column-subtitle {
  font-weight: bold;
  font-size: var(--text-medium-large);
  color: darkgreen;
  margin: 0;
}

.about-skills-list {
  font-size: var(--text-medium);
  list-style-type: none;
  margin: 0px;
  padding: 4px 0px 24px 0px;
}

.about-skills-list>li:nth-child(n) {
  padding-top: 8px;
}

.about-projects-container {
  padding-top: 30px;
  scroll-margin-top: 92px; /*the height of the header, so on "design / dev / story" button jump, it goes to the top of the "projects" page div*/
}

/*WRITING STYLES*/
.writing-main {
  font-size: var(--text-medium);
}

.writing-single {}

.writing-link {
  color: black;
  text-decoration: none;
}

.writing-title-big {
  font-size: var(--text-medium-large);
  text-decoration: none;
  font-family: "Source Sans Pro Medium";
  border-bottom: 4px solid #F95409;

  background-image: linear-gradient(peachpuff, peachpuff);
  background-size: 100% 0px;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  transition: background-size 1s, background-position .5s ease-in-out;
}

.writing-title-big:hover {
  background-image: linear-gradient(peachpuff, peachpuff);
  text-decoration: none;
  background-size: 100% 100%;
  background-position: 0% 100%;
  transition: background-position .7s, background-size .5s ease-in-out;
}

.writing-title {
  font-family: "Source Sans Pro Medium";
  border-bottom: 2px solid lightsalmon;
}

.writing-title:hover {
  background-color: peachpuff;
  border-bottom: 2px solid #F95409;
}

.writing-source {
  font-size: var(--text-medium);
}

.art-flex-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}

/* .art-flex-gallery-col {
  display: flex-col;
  flex: 1;
} */

.art-img {
  width: 300px;
  height: auto;
  margin-right: 12px;
  margin-bottom: 12px;
  border: 1px solid peachpuff;
  border-radius: 2px;
  box-shadow: 4px 4px lightsalmon;
  transition: transform .2s;
}

.art-img:hover {
  box-shadow: 4px 4px #F95409;
  transform: scale(1.05);
}

/*EXPLORATORY STYLES*/
.exploratory-main {
  font-size: var(--text-medium);
}

.exploratory-single {
  padding-bottom: 8px;
}

.exploratory-link {
  color: black;
  text-decoration: none;
  border-bottom: 4px solid lightsalmon;
}

.exploratory-link:hover {
  text-decoration: none;
  background-color: peachpuff;
  border-bottom: 4px solid #F95409;
}


/*MEDIA CHANGES*/
@media only screen and (max-width: 1000px) {
  .about-title-blurb-more {
    margin-top: -0.5em;
  }

  .about-external-link-container {
    margin-top: -0.5em;
  }

  .about-vector-container {
    width: 450px;
  }

  .external-logo-img {
    max-height: 28px;
    margin-right: 6px;
  }

  .about-title {
    font-size: var(--text-large-mega);
    margin-bottom: -1rem;
  }

  .about-title-blurb {
    font-size: var(--text-medium-large);
  }

  .about-title-blurb-more {
    margin-top: -0.1rem;
    font-size: var(--text-medium);
  }

  .about-vector-container {
    max-width: 350px;
  }

  .about-skills-column-title {
    font-size: var(--text-medium);
  }

  .about-skills-column-subtitle {
    font-size: var(--text-default);
  }

  .about-skills-list {
    font-size: var(--text-small);
  }

  .page-title {
    font-size: var(--text-large-mega);
  }
  
  .art-flex-gallery {
    display: block;
  }

  .footer-text, .footer-text-handcraft {
    font-size: var(--text-small);
  }
}

@media only screen and (max-width: 800px) {
  .header {
    flex-direction: column;
    padding: 12px 28px 4px 28px;
  }

  .header-nav {
    align-self: flex-start;
    text-align: left;
  }

  .main-display {
    padding-left: 28px;
    padding-right: 28px;
  }

  .about-hello-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -1rem;
    padding: 40px 0px 0px 0px;
  }

  .about-title-blurb-more {
    margin-top: -0.1rem;
    font-size: var(--text-default);
  }

  .about-vector-container-small-screen {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .about-vector-container {
    max-width: 300px;
  }

  .about-skills-img {
    width: 34px;
  }
  .about-skills-img-small {
    width: 26px;
  }

  .about-skills-container {
    border: solid;
    border-color: #A09494;
    border-width: 12px 2px 2px 2px;
    /*N, E, S, W*/

    display: grid;
    grid-template-columns: 1fr;
    /*fr stands for flex factor (1fr for each column)*/
    text-align: center;
  }

  .about-skills-container>.about-skills-column:nth-child(1),
  .about-skills-column:nth-child(2) {
    border-right: 0px;
    border-bottom: 2px solid #A09494;
  }

  .about-skills-column {
    padding: 24px 12px 16px 12px;
  }

  .art-flex-gallery {
    display: grid;
    grid-template-columns: 1fr;
  }

  .footer-logo {
    width: 50px;
  }

}

@media only screen and (max-width: 530px) {
  .external-logo-img {
    max-height: 24px;
    margin-right: 4px;
  }

  .about-vector-container {
    max-width: 250px;
  }

  .footer-text, .footer-text-handcraft {
    font-size: var(--text-small);
  }
}