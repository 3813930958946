.projects-main {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.projects-single {
   /* float: left;
   background-color: #2196F3;
   color: #ffffff;
   width: 100px;
   line-height: 100px;
   text-align: center;
   margin: 2px; */
   display: none;
   align-items: flex-start;
   margin-bottom: 50px;
   padding: 20px;
   display: none;

   height: auto;
   border-top: 2px solid #A09494;
   border-left: 2px solid #A09494;

   border-radius: 30px;
   box-shadow: 4px 4px lightsalmon;
   transition: transform .7s;
 }

.projects-single:hover {
  cursor: pointer;
  box-shadow: 7px 7px tomato;
  /* transform: scale(1.02); */
}

 .show {
  display: flex;
 }

 .projects-filter-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
 }
 
 /* Style the buttons */
 .projects-filter-button {
   background-color: #fff;
   border-style: solid;
   border-width: 2px;
   border-radius: 28px;
   padding: 12px 16px;
   margin-right: 8px;
   margin-bottom: 8px;
   font-family: "Source Sans Pro Medium";
   font-size: var(--text-medium-large);
   text-align: center;
   cursor: pointer;
 }
 
 .projects-filter-button:hover {
   border-color: forestgreen;
   color: forestgreen;
 }

 .projects-filter-button-container {
  margin-bottom: 24px;
 }
 
 .active-button, .active-button:hover {
   background-color: #000;
   color: #fff;
 }

 .projects-img {
  flex: 1;
  margin-right: 24px;
  width: 250px;
  height: auto;
  border: 2px solid #A09494;
  border-radius: 4px;
  /* box-shadow: 7px 7px lightsalmon; */
  transition: transform .2s;
}
/* .projects-img:hover {
  cursor: pointer;
  box-shadow: 7px 7px tomato;
  transform: scale(1.05);
} */

.projects-text {
  flex: 2;
  text-align: top;
}
.projects-text-title {
  margin-top: 0;
  font-family: "Source Sans Pro Medium";
  font-size: var(--text-medium-large);
  color: black;
}
.projects-text-title:hover {
  text-decoration: underline;
  color: darkgreen;
}
.projects-text-explainer {
  margin-top: 0;
  font-size: var(--text-default);
}

.projects-link {
  color: black;
  text-decoration: none;
  font-family: "Source Sans Pro Medium";
  border-bottom: 4px solid #F95409;

  background-image: linear-gradient(peachpuff, peachpuff);
  background-size: 100% 0px;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  transition: background-size 1s, background-position .5s ease-in-out;
}
.projects-link:hover {
  background-image: linear-gradient(peachpuff, peachpuff);
  text-decoration: none;
  background-size: 100% 100%;
  background-position: 0% 100%;
  transition: background-position .7s, background-size .5s ease-in-out;
}

/*MEDIA CHANGES*/
@media only screen and (max-width: 1000px) {
  .projects-single {
    flex-direction: column;
    padding-bottom: 24px;
    margin-bottom: 36px;
  }
  /* Style the buttons */
  .projects-filter-button {
    padding: 8px 12px;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: var(--text-medium-large);
    border-radius: 24px;
  }

  .projects-filter-button-container {
    margin-bottom: 18px;
   }

  .projects-text {
    padding-top: 16px;
  }

  .projects-text-title {
    font-size: var(--text-default);
  }

  .projects-text-explainer {
    font-size: var(--text-small);
  }
}

@media only screen and (max-width: 530px) {
  .projects-filter-button {
    padding: 8px 8px;
    margin-right: 4px;
    margin-bottom: 10px;
    font-size: var(--text-medium);
    border-radius: 20px;
  }

  .projects-img {
    width: 200px;
  }
}